import React from "react";
import Layout from "../Components/layout";
import ValidateEmailc from "../Components/validateEmailc";
import { Seo } from "../Components/seo";


export default function Validateemail() {
  return (
    <Layout>
      <ValidateEmailc />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)